import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { Layer } from "grommet"

import MakerSwapUnavailableComponent from "./MakerSwapUnavailableComponent"
import { getUsersProxy, getUserCdps, getCdpDetails, performSwap } from "../../controllers/MakerSwap"
import { MakerToMakerOptions } from "../../controllers/Platforms"
import getGasPrice from "../../controllers/GasStation"
import TermsConditions from "../copy/TermsConditions"
import ServiceFees from "../copy/ServiceFees"
import { MKR_COLLATERAL, RELEASE_TOKEN } from "../../constants"

import Checkbox from "../checkbox"
import Dropdown from "../dropdown"
import { Bold, BlankButton, Button, Fees, Footer, Label, LabelCenter, Row } from "../states/Swap.styles"

const ExtraOptions = ({ option, ilk, setFrom, setTo, toAsset }) => {
	let [fromColl, setFromColl] = useState(ilk !== null ? ilk : null)
	// let [toColl, setToColl] = useState(null)

	function setAsset(isFrom, asset) {
		if (isFrom) {
			setFrom(asset)
			setFromColl(asset)
		} else {
			setTo(asset)
			// setToColl(asset)
		}
	}

	function evaluateDefault(returnsValue) {
		if (ilk !== null) {
			setFrom(ilk)
			return returnsValue ? ilk : true
		} else {
			return returnsValue ? fromColl : false
		}
	}

	if (option === MakerToMakerOptions.SWAP) {
		return (
			<>
				<Dropdown
					placeholder="Select current vault collateral type"
					showKey={true}
					options={MKR_COLLATERAL}
					label="Current Vault Collateral"
					onSelect={(asset) => setAsset(true, asset)}
					value={evaluateDefault(true)}
					disabled={evaluateDefault(false)}
				/>
				<Dropdown
					placeholder="Select new vault collateral type"
					showKey={true}
					options={MKR_COLLATERAL}
					label="New Vault Collateral"
					onSelect={(asset) => setAsset(false, asset)}
					value={toAsset}
				/>
			</>
		)
	} else if (option === MakerToMakerOptions.RELEASE) {
		return (
			<>
				<Dropdown
					placeholder="Select current vault collateral type"
					showKey={true}
					options={MKR_COLLATERAL}
					label="Current Vault Collateral"
					onSelect={(asset) => setAsset(true, asset)}
					value={evaluateDefault(true)}
					disabled={evaluateDefault(false)}
				/>
				<Dropdown
					placeholder="Select collateral release asset"
					showKey={true}
					showOnlyKey={true}
					options={RELEASE_TOKEN}
					label="Asset To Release Into"
					onSelect={(asset) => setAsset(false, asset)}
					value={toAsset}
				/>
			</>
		)
	} else {
		return <></>
	}
}

const MakerSwapComponent = ({ account, loading, setLoading, web3 }) => {
	const { addToast, removeAllToasts } = useToasts()
    let [userProxy, setUserProxy] = useState(null)

    useEffect(() => {
        if (web3 && account) {
            (async () => {
				setLoading(true)
				let proxy = await getUsersProxy(web3, account)
				setUserProxy(proxy)
				setLoading(false)
            })()
        }
	}, [web3, account, setLoading])

	let [cdps, setCdps] = useState({ "": "Loading your vaults..." })
	let [cdpDataLoaded, setCdpDataLoaded] = useState(false)
	let [selectedCdp, setSelectedCdp] = useState(null)

	useEffect(() => {
		if (!cdpDataLoaded) {
			let cdpArr = Object.keys(cdps).reverse()
			if (cdpArr[0] !== "") {
				;(async () => {
					setCdpDataLoaded(true)
					console.log(`Getting ${cdpArr.length} CDP details`)
					let prevCdps = { ...cdps }
					for (let index in cdpArr) {
						let cdpId = cdpArr[index]
						if (cdps[cdpId] && cdps[cdpId].loading) {
							let newDetails = await getCdpDetails(web3, cdpId)
							prevCdps[cdpId] = newDetails
							setCdps(prevCdps)
						}
					}
				})()
			}
		}
	}, [cdps, web3, cdpDataLoaded])

	useEffect(() => {
		(async () => {
			if (userProxy) {
				setLoading(true)
				let cdpList = await getUserCdps(userProxy, web3)
				setCdps(cdpList)
				setLoading(false)
			}
		})()
	}, [web3, userProxy, setLoading])

	let [option, setOption] = useState(null) // Type of swap
	let [fromAsset, setFromAsset] = useState(null)
	let [toAsset, setToAsset] = useState(null)

	let [gasPrice, setGasPrice] = useState(null)

	useEffect(() => {
		(async () => {
			let newGasPrice = await getGasPrice()
			setGasPrice(newGasPrice)
		})()
	}, [toAsset, fromAsset])


	let [checked, setChecked] = useState(false)
	let [showTandCs, setShowTandCs] = useState(false)
	let [showServiceFees, setShowServiceFees] = useState(false)
	let [showCreateVault, setShowCreateVault] = useState(false)

    let [message, setMessage] = useState(null)
    let [error, setError] = useState(null)
	let [txHash, setTxhash] = useState(null)

	useEffect(() => {
		if (message && txHash) {
			let txLink = (
				<>
					{message}{" "}
					<a href={`https://etherscan.io/tx/${txHash}`} target="_blank" rel="noopener noreferrer">
						See transaction.
					</a>
				</>
			)
			addToast(txLink, { appearance: 'success', autoDismiss: true, onDismiss: () => { setMessage(null); setTxhash(null)} })
		} else if (message) {
			addToast(message, { appearance: "info", autoDismiss: true, onDismiss: () => setMessage(null) })
		} else if (error) {
			addToast(error, { appearance: "error", autoDismiss: true, onDismiss: () => setError(null)})
		}

	}, [message, error, txHash, addToast])

    async function swap() {
		setLoading(true)
		addToast("Performing swap... this may take a few minutes", { appearance: 'info' })
        console.log(`Account: ${account}, DSProxy: ${userProxy}, cdpId: ${selectedCdp}, option: ${option}, from: ${fromAsset}, to: ${toAsset}`)
        try {
			let newGasPrice = gasPrice
			if (gasPrice === null) {
				newGasPrice = await getGasPrice()
				setGasPrice(newGasPrice)
			}
			let result = await performSwap(web3, account, selectedCdp, fromAsset, toAsset, option, gasPrice)
			removeAllToasts()
            setTxhash(result ? result.transactionHash : null)
            setMessage(`${result && result.status ? "Successful" : "Unsuccessful"} collateral swap!`)
        } catch (e) {
			removeAllToasts()
			setError(`😱 Error occured! ${e.message}`)
			console.log(e)
        }
        setLoading(false)
	}

	function clearAndSetOption(option) {
		console.log("clearing")
		setOption(option)
		setToAsset(null)
	}

    if (!userProxy) {
        return (
			<MakerSwapUnavailableComponent
				account={account}
				loading={loading}
				setLoading={setLoading}
				setUserProxy={setUserProxy}
				web3={web3}
				setMessage={setMessage}
				setError={setError}
				addToast={addToast}
				removeAllToasts={removeAllToasts}
			/>
		)
    } else {
        return (
			<>
				<Dropdown
					placeholder="Select vault action to take"
					options={MakerToMakerOptions}
					label="Vault Action"
					onSelect={(option) => clearAndSetOption(option)}
					value={MakerToMakerOptions[option]}
				/>
				<Dropdown placeholder="Select your vault" showKey={true} options={cdps} label="Vault" onSelect={setSelectedCdp} value={selectedCdp} />
				<LabelCenter>
					<BlankButton
						onClick={(event) => {
							event.preventDefault()
							setShowCreateVault(true)
						}}
					>
						Or create a new Vault
					</BlankButton>
				</LabelCenter>
				<ExtraOptions
					option={MakerToMakerOptions[option]}
					ilk={selectedCdp && cdps[selectedCdp] && cdps[selectedCdp].ilk ? cdps[selectedCdp].ilk : null}
					setFrom={setFromAsset}
					setTo={setToAsset}
					toAsset={toAsset}
				/>
				<Fees>
					<Row>
						<Bold>Fees</Bold>
						<Bold>Details</Bold>
					</Row>
					<Row>
						<Label>Gas Price (Suggested)</Label>
						<Label>{gasPrice !== null ? gasPrice : "TBC"} gwei</Label>
					</Row>
					<Row>
						<Label>
							<BlankButton
								onClick={(event) => {
									event.preventDefault()
									setShowServiceFees(true)
								}}
							>
								Service Fee
							</BlankButton>
						</Label>
						<Label>0.20%</Label>
					</Row>
				</Fees>
				<Footer>
					<Checkbox
						onChange={setChecked}
						node={
							<Label>
								I agree to the{" "}
								<BlankButton
									onClick={(event) => {
										event.preventDefault()
										setShowTandCs(true)
									}}
								>
									Terms and Conditions
								</BlankButton>
							</Label>
						}
					/>
					<Button onClick={() => swap()} disabled={loading || !selectedCdp || !option || !checked}>
						{loading ? "Awaiting Confirmation" : "Swap Collateral"}
					</Button>
				</Footer>
				{showTandCs && (
					<Layer onEsc={() => setShowTandCs(false)} onClickOutside={() => setShowTandCs(false)}>
						<TermsConditions closeAction={() => setShowTandCs(false)} />
					</Layer>
				)}
				{showServiceFees && (
					<Layer onEsc={() => setShowServiceFees(false)} onClickOutside={() => setShowServiceFees(false)}>
						<ServiceFees closeAction={() => setShowServiceFees(false)} />
					</Layer>
				)}
				{showCreateVault && (
					<Layer onEsc={() => setShowCreateVault(false)} onClickOutside={() => setShowCreateVault(false)}>
						<MakerSwapUnavailableComponent
							account={account}
							loading={loading}
							setLoading={setLoading}
							setUserProxy={setUserProxy}
							web3={web3}
							setMessage={setMessage}
							setError={setError}
							addToast={addToast}
							removeAllToasts={removeAllToasts}
							closeAction={() => setShowCreateVault(false)}
							setCdpDataLoaded={setCdpDataLoaded}
						/>
					</Layer>
				)}
			</>
		)
    }
}

export default MakerSwapComponent
