import React from "react"

import { Container, Select, SelectDisabled, Wrapper, Item, Button, ButtonDisabled, Labels, Name, NamePlaceholder, Row, Label } from "./styles"

class Dropdown extends React.Component {
  state = {
    displayMenu: false,
    selected: this.props.placeholder
  }

  showDropdownMenu = event => {
    event.preventDefault()
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu)
    })
  }

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu)
    })
  }

  formatDisplay = (showKey, showOnlyKey, options, value) => {
    if (options[value] === undefined) {
      return value
    }

    let display
    if (options[value] && options[value].constructor === Object) {
      if (options[value].loading) {
        display = `${value} - loading details...`
      } else {
        display = `${value} - ${options[value].ilk} (${options[value].cratio}%)`
      }
    } else {
      display = showKey ? 
        showOnlyKey ? value : `${value} (${options[value]})` 
        : options[value]
    }
    return display
  }

  renderOptions = () => {
    const { onSelect, options, showKey, showOnlyKey } = this.props
    let optionsList =  Object.keys(options).map(key => {
      let name = this.formatDisplay(showKey, showOnlyKey, options, key)
      return (
        <Item disabled key={key} onClick={() => onSelect(key)}>
          <Row>
            <Name>{name}</Name>
          </Row>
        </Item>
      )
    })

    return showKey && !showOnlyKey ? optionsList.reverse() : optionsList
  }

  render() {
    const { displayMenu } = this.state
    const { label, error, value, placeholder, options, showKey, showOnlyKey, disabled } = this.props
    let display = this.formatDisplay(showKey, showOnlyKey, options, value)
    return (
		<Container>
			<Labels>
				<Label>{label}</Label>
				<Label>{error}</Label>
			</Labels>
			{disabled ? (
				<SelectDisabled>
					<ButtonDisabled>
						<Row>{!value ? <NamePlaceholder>{placeholder}</NamePlaceholder> : <Name>{display}</Name>}</Row>
					</ButtonDisabled>
				</SelectDisabled>
			) : (
				<Select>
					<Button onClick={this.showDropdownMenu}>
						<Row>{!value ? <NamePlaceholder>{placeholder}</NamePlaceholder> : <Name>{display}</Name>}</Row>
					</Button>
					{displayMenu && <Wrapper>{this.renderOptions()}</Wrapper>}
				</Select>
			)}
		</Container>
	)
  }
}

export default Dropdown
