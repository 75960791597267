export const PlatformOptions = {
	Maker: "MakerDAO MCD"
	// Compound: "compound",
	// DyDx: "dydx",
	// Bzx: "bZx",
}

export const MakerToMakerOptions = {
	SWAP: "Swap vault collateral",
	RELEASE: "Release vault collateral",
}