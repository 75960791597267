/**
 * Returns the addresses needed for data encoding (mainnet!)
 * MakerDAO: https://changelog.makerdao.com/
 * Aave: https://developers.aave.com/#the-lendingpooladdressesprovider and https://github.com/aave/aave-protocol/blob/478244b0c5f85431b067a058d55bc7eb2b886cdb/contracts/libraries/EthAddressLib.sol#L10
 * Uniswap factory contract currently hardcoded as 0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95
 * @returns { { maker: {}, aave: {}, tokens: {} } }
 */
const getAddresses = () => {
  return {
		collateralSwap: {
			beta: "0xfd14431285A570adC839C73Cc973542E0FfEe56F",
		},
		maker: {
			proxyRegistry: "0x4678f0a6958e4D2Bc4F1BAF7Bc52E8F3564f3fE4",
			cdpManager: "0x5ef30b9986345249bc32d8928B7ee64DE9435E39",
			jug: "0x19c0976f590D67707E62397C87829d896Dc0f1F1",
			ethJoin: "0x2F0b23f53734252Bda2277357e97e1517d6B042A",
			batJoin: "0x3D0B1912B66114d4096F48A8CEe3A56C231772cA",
			daiJoin: "0x9759A6Ac90977b93B58547b4A71c78317f391A28",
			usdcJoin: "0xA191e578a6736167326d05c119CE0c90849E84B7",
			wbtcJoin: "0xBF72Da2Bd84c5170618Fbe5914B0ECA9638d5eb5",
			ilkBatA: "BAT-A",
			ilkEthA: "ETH-A",
			ilkUsdcA: "USDC-A",
			ilkWbtcA: "WBTC-A",
		},
		aave: {
			ethAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
		},
		tokens: {
			dai: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
			bat: "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
			usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
			wbtc: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
		},
  }
}

export default getAddresses
