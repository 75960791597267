import React from "react";
import { CircleInformation } from "grommet-icons";

import Web3Unavailable from "../states/Web3Unavailable";
import Web3NotEnabled from "../states/Web3NotEnabled";
import Web3Enabled from "../states/Web3Enabled";

import {
  Body,
  Button,
  Container,
  Description,
  Header,
  Title,
  Tag,
  Wrapper,
  Headline
} from "./styles";

const Modal = ({
  account,
  loadWeb3,
  loading,
  setLoading,
  web3,
  web3Status
}) => {

  return (
		<Wrapper>
			<Container>
				<Header>
					<Headline>
						<Title>Collateral Swap</Title>
						<Tag>Alpha</Tag>
					</Headline>
					<Description>
						Instantly swap the collateral of your vault.
						<Button onClick={() => window.open("https://docs.collateralswap.com/", "_blank")}>
							<CircleInformation size="small" color="#07849f" />
						</Button>
					</Description>
				</Header>
				<Body>
					{web3Status < 0 ? (
						<Web3Unavailable />
					) : web3Status === 0 ? (
						<Web3NotEnabled loadWeb3={loadWeb3} />
					) : (
						<Web3Enabled web3={web3} account={account} loading={loading} setLoading={setLoading} />
					)}
				</Body>
			</Container>
		</Wrapper>
  )
};

export default Modal;
