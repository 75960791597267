export const ACTIONS = {
    MAKER_SWAP_ACTIONS: "MAKER_SA",
    DEX_UNISWAP_ACTIONS: "DEX_UNISWAP",
    NO_ACTION: "NO_ACTION",
}

export const ILKS = {
    BATA: "BAT-A",
    ETHA: "ETH-A",
    USDCA: "USDC-A",
    WBTC: "WBTC-A",
}

export const MKR_COLLATERAL = {
    "ETH-A": "ETH",
    "USDC-A": "USDC",
    "BAT-A": "BAT",
    "WBTC-A": "WBTC",
}

export const RELEASE_TOKEN = {
    "Original collateral" : null, // release as original
    "DAI": "0x6b175474e89094c44da98b954eedeac495271d0f",
    "USDC": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    "sUSD": "0x57ab1ec28d129707052df4df418d58a2d46d5f51",
    "LEND": "0x80fB784B7eD66730e8b1DBd9820aFD29931aab03",
    "WBTC": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    "ETH": "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    "BAT": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
    "MKR": "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
    "SNX": "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
    "LINK": "0x514910771af9ca656af840dff83e8264ecf986ca",
}