const endPoint = "https://api.coingecko.com/api/v3/"

export const getEthPrice = async () => {
    let prices = await getUSDPrices(["ETH"])
    console.log("Fetched ETH price: ", prices.ETH)
    return prices.ETH
}

async function getUSDPrices(assets) {
	const response = await fetch(`${endPoint}/simple/price?ids=${assets.map((e) => CoinGeckoAssets[e]).join(",")}&vs_currencies=usd`)
	const data = await response.json()
	let prices = {}

	Object.keys(data).map((e) => {
		let symbol = Object.keys(CoinGeckoAssets).find((k) => CoinGeckoAssets[k] === e)
		prices[symbol] = data[e].usd
	})
	return prices
}

export const CoinGeckoAssets = {
	DAI: "dai",
	USDC: "usd-coin",
	TUSD: "true-usd",
	SUSD: "nusd",
	BUSD: "binance-usd",
	ETH: "ethereum",
	LEND: "ethlend",
	BAT: "basic-attention-token",
	KNC: "kyber-network",
	LINK: "chainlink",
	MANA: "decentraland",
	MKR: "maker",
	REP: "augur",
	SNX: "havven",
	WBTC: "wrapped-bitcoin",
	ZRX: "0x",
}

export const AaveAssets = ["DAI", "USDC", "TUSD", "SUSD", "BUSD", "ETH", "LEND", "BAT", "KNC", "LINK", "MANA", "MKR", "REP", "SNX", "WBTC", "ZRX"]