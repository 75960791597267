import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { ToastProvider } from "react-toast-notifications"
import { GlobalStyle } from "./App.styles.js"

import { Grommet } from "grommet"
import { deepMerge } from "grommet/utils"
import { grommet } from "grommet/themes"

const customGrommetTheme = {
	checkBox: {
		border: {
			color: "#07849f"
		},
		color: "#07849f",
		hover: {
			border: {
				color: "#07849f"
			}
		}
	},
	layer: {
		zIndex: "300"
	}
}

ReactDOM.render(
	<ToastProvider placement="top-center" autoDismissTimeout={7000}>
		<GlobalStyle />
		<Grommet background={"none"} theme={deepMerge(grommet, customGrommetTheme)}>
			<App />
		</Grommet>
	</ToastProvider>,
	document.getElementById("root")
)
