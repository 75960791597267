import React, { useState, useEffect } from "react"
import { Box, TextInput } from "grommet"
import { Close } from "grommet-icons"

import { createProxyOpenLockETHAndDraw } from "../../controllers/MakerSwap"
import { getEthPrice } from "../../controllers/PriceFetch"

import { Bold, Button, Fill, Footer, Label, LabelSmall } from "../states/Swap.styles"
import { CloseButton, Header, Title } from "../popup/Popup.styles"


const openVault = async ({
	web3,
	account,
	setLoading,
	setMessage,
	setError,
	addToast,
	removeAllToasts,
	setUserProxy,
	eth,
	dai,
	setCdpDataLoaded,
}) => {
	try {
		addToast(`Creating vault with ${eth} ETH and drawing ${dai} DAI... this may take a few minutes`, { appearance: "info" })
		setLoading(true)
		let proxyAddress = await createProxyOpenLockETHAndDraw(web3, account, eth, dai)
		removeAllToasts()
		setLoading(false)
		setMessage("Successfully created vault! You can now use Collateral Swap!")
		setUserProxy(proxyAddress)
		setCdpDataLoaded(true)
	} catch (e) {
		removeAllToasts()
		setLoading(false)
		setError(`😱 Error occured: ${e.message}. Please check settings and try again.`)
		return
	}
}

const MakerSwapUnavailableComponent = ({
	account,
	loading,
	setLoading,
	setUserProxy,
	web3,
	setMessage,
	setError,
	addToast,
	removeAllToasts,
	closeAction,
	setCdpDataLoaded,
}) => {
	let [dai, setDai] = useState("20")
	let [eth, setEth] = useState("0.5")
	let [cRat, setCrat] = useState(20)

	useEffect(() => {
		async function updateCRatio() {
			const ethPrice = await getEthPrice()
			let ratio = (ethPrice * eth) / dai
			ratio = (ratio * 100).toFixed(2)
			setCrat(ratio)
		}

		updateCRatio()
	}, [dai, eth])

	const main = (
		<Fill>
			<Bold>To use Collateral Swap, you'll need an 'official' MakerDAO MCD vault.</Bold>
			<br />
			<Label>
				You can set up a Vault on Maker's official{" "}
				<a href="https://oasis.app/borrow" target="_blank" rel="noopener noreferrer">
					Oasis.app/borrow
				</a>{" "}
				website.
			</Label>
			<br />
			<Label>Alternatively, you can create an Oasis compatible ETH-A vault in 1 transaction, using the details below.</Label>
			<br />
			<Box gap="xxsmall">
				<Label>ETH to use as collateral: </Label>
				<TextInput placeholder="0.5" size="small" value={eth} onChange={(event) => setEth(event.target.value)} />
			</Box>
			<br />
			<Box gap="xxsmall">
				<Label>DAI debt to draw from vault (at least 20 DAI): </Label>
				<LabelSmall>
					Ensure the collateral ratio is <strong>greater than 150%</strong>. Currently: {cRat}%
				</LabelSmall>
				<TextInput placeholder="0.5" size="small" value={dai} onChange={(event) => setDai(event.target.value)} />
			</Box>
			<br />
			<LabelSmall>
				You are sending the contract {eth} ETH to use as collateral for your vault and drawing {dai} DAI debt back to your account. With
				current ETH prices (powered by CoinGecko API), your collatal ratio is {cRat}%<p></p>
				You can then manage this vault on Oasis.app/borrow if needed. You will always have full control over the collateral and debt.
			</LabelSmall>
			<Footer>
				<Button
					onClick={() =>
						openVault({
							web3,
							account,
							setLoading,
							setMessage,
							setError,
							addToast,
							removeAllToasts,
							setUserProxy,
							eth,
							dai,
							setCdpDataLoaded,
						})
					}
					disabled={loading}
				>
					{loading ? "Processing..." : "Open Vault and Draw DAI"}
				</Button>
			</Footer>
		</Fill>
	)

	if (!closeAction) {
		return main
	} else {
		return (
			<Header>
				<Title>
					Create New Vault
					<CloseButton onClick={() => closeAction()}>
						<Close />
					</CloseButton>
				</Title>
				{main}
			</Header>
		)
	}
}

export default MakerSwapUnavailableComponent