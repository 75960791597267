import React, { useState } from "react"
import { CheckBox } from "grommet"


import { PadV } from "./styles"

const Checkbox = ({ node, onChange }) => {
    let [checked, setChecked] = useState(false)
    const handleChange = (event) => {
        setChecked(event.target.checked)
        onChange(event.target.checked)
    }

    return (
		<PadV>
			<CheckBox label={node} checked={checked} onChange={handleChange} />
		</PadV>
	)
}

export default Checkbox
