import getAddresses from "../providers/getAddresses"
import { MakerToMakerOptions } from "./Platforms"
import getGasPrice from "./GasStation"

import { ACTIONS, ILKS, RELEASE_TOKEN } from "../constants"
import DSProxy from "../abi/DSProxy.json"
import ProxyRegistry from "../abi/ProxyRegistry.json"
import CdpManager from "../abi/CdpManager.json"
import SwapAaveCore from "../contracts/SwapAaveCore.json"
import MakerProxyActions from "../contracts/MakerProxyActions.json"
import MakerActions from "../contracts/MakerActions.json"
import AddressProvider from "../contracts/AddressProvider.json"
import MkrActions from "../contracts/MakerActions.json"

export const createProxyOpenLockETHAndDraw = async (web3, account, eth, dai) => {
	let networkId = await web3.eth.net.getId()
	let mkrActionsDeployed = MakerActions.networks[networkId]
	let mkrActions = new web3.eth.Contract(MakerActions.abi, mkrActionsDeployed && mkrActionsDeployed.address)
	let addressProviderDeployed = AddressProvider.networks[networkId]

	let daiAddress = "0x6B175474E89094C44Da98b954EedeAC495271d0F"
	let daiAmount = web3.utils.toWei(`${dai}`, "ether")
	let gasPrice = await getGasPrice()

	await mkrActions.methods
		.createProxyOpenLockETHAndDraw(addressProviderDeployed.address, daiAmount, daiAddress)
		.send({
			from: account,
			gas: 5000000,
			gasPrice: web3.utils.toWei(`${gasPrice}`, "gwei"),
			value: web3.utils.toWei(`${eth}`, "ether")
		})
		.catch((e) => {
			throw Error(`Error creating vault: ${e.message}`)
		})

	let myProxyAddress = await getUsersProxy(web3, account)
	return myProxyAddress
}

/**
 * @param {  } web3 The web3 instance (usually metamask or their wallet)
 * @param {  } account The account to use for sending transactions
 * @param { Number } cdpId The CDP / Vault ID
 * @param { String } fromAsset The CDP / Vault current ilk, e.g. "BAT-A"
 * @param { String } toAsset The CDP / Vault conversion ilk, e.g. "USDC-A"
 * @param { MakerToMakerOptions } option The type of conversion to do
 * @param { Number } gasPrice The gasPrice to use in gwei
 */
export const performSwap = async (web3, account, cdpId, fromAsset, toAsset, option, gasPrice) => {
  let addresses = getAddresses()

  function getJoin(ilk) {
	  let ethJoin = addresses.maker.ethJoin
	  let batJoin = addresses.maker.batJoin
	  let usdcJoin = addresses.maker.usdcJoin
	  let wbtcJoin = addresses.maker.wbtcJoin

	  switch (ilk) {
		  case ILKS.WBTC: return wbtcJoin;
		  case ILKS.USDCA: return usdcJoin;
		  case ILKS.BATA: return batJoin;
		  case ILKS.ETHA: return ethJoin;
		  default: throw Error(`Invalid Ilk for getJoin: ${ilk}`)
	  }
  }

  function getToken(ilk) {
	  let batAddress = addresses.tokens.bat
	  let ethAddress = addresses.aave.ethAddress
	  let usdcAddress = addresses.tokens.usdc
	  let wbtcAddress = addresses.tokens.wbtc

	  switch (ilk) {
		  case ILKS.WBTC: return wbtcAddress;
		  case ILKS.USDCA: return usdcAddress;
		  case ILKS.BATA: return batAddress;
		  case ILKS.ETHA: return ethAddress;
		  default: throw Error(`Invalid Ilk for getToken: ${ilk}`)
	  }
  }

  let daiAddress = addresses.tokens.dai

  let networkId = await web3.eth.net.getId()
  let addressProviderDeployed = AddressProvider.networks[networkId]
  
  let swapAaveCoreDeployed = SwapAaveCore.networks[networkId]
  let mkrProxyActionsDeployed = MakerProxyActions.networks[networkId]
  let mkrProxyActions = new web3.eth.Contract(MakerProxyActions.abi, mkrProxyActionsDeployed && mkrProxyActionsDeployed.address)
  let myProxyAddress = await getUsersProxy(web3, account)
  let myProxyInstance = new web3.eth.Contract(DSProxy, myProxyAddress)

  if (myProxyAddress === null) {
	  throw Error(`No proxy address available!`)
  }

  let swapProviderData
  let calldata

  switch (MakerToMakerOptions[option]) {
		case MakerToMakerOptions.SWAP:
			console.log("Swapping")
			swapProviderData = web3.eth.abi.encodeParameters(
				["bytes32", "bytes32", "bytes32"],
				[
					web3.utils.utf8ToHex(ACTIONS.MAKER_SWAP_ACTIONS),
					web3.utils.utf8ToHex(ACTIONS.DEX_UNISWAP_ACTIONS),
					web3.utils.utf8ToHex(ACTIONS.MAKER_SWAP_ACTIONS)
				]
			)

			calldata = mkrProxyActions.methods
				.proxyAction(
					addressProviderDeployed.address,
					swapAaveCoreDeployed.address,
					myProxyAddress,
					cdpId,
					web3.utils.utf8ToHex(`${toAsset}`), // ilk of new collateral
					getJoin(fromAsset), // _gemJoinFrom
					getJoin(toAsset), // _gemJoinTo
					getToken(toAsset), // new collateral
					getToken(fromAsset), // old collateral
					daiAddress,
					swapProviderData
				)
				.encodeABI()
			break

		case MakerToMakerOptions.RELEASE:
			console.log("Releasing")
			swapProviderData = web3.eth.abi.encodeParameters(
				["bytes32", "bytes32", "bytes32"],
				[
					web3.utils.utf8ToHex(ACTIONS.MAKER_SWAP_ACTIONS),
					web3.utils.utf8ToHex(RELEASE_TOKEN[toAsset] !== null ? ACTIONS.DEX_UNISWAP_ACTIONS : ACTIONS.NO_ACTION),
					web3.utils.utf8ToHex(ACTIONS.NO_ACTION)
				]
			)

			calldata = mkrProxyActions.methods
				.proxyAction(
					addressProviderDeployed.address,
					swapAaveCoreDeployed.address,
					myProxyAddress,
					cdpId,
					web3.utils.utf8ToHex("ETH-A"), // not used
					getJoin(fromAsset), // _gemJoinFrom
					"0x0000000000000000000000000000000000000000", // _gemJoinTo
					RELEASE_TOKEN[toAsset] !== null ? RELEASE_TOKEN[toAsset] : "0x0000000000000000000000000000000000000000", // new collateral
					getToken(fromAsset), // old collateral
					daiAddress,
					swapProviderData
				)
				.encodeABI()
			break

		default:
			throw Error(`Invalid option: ${option}`)
  }

  console.log("sending to your proxy...")
  let response = await myProxyInstance.methods
    .execute(`${mkrProxyActionsDeployed.address}`, `${calldata}`)
    .send({
      from: account,
      gas: 2000000,
      gasPrice: web3.utils.toWei(`${gasPrice}`, "gwei")
	})
	.catch(e => {
		throw Error(`proxyInstance execute(): ${e.message}`)
	})

  console.log(response)
  return response
}

export const getUsersProxy = async (web3, account) => {
  let proxyRegistryInstance = new web3.eth.Contract(
    ProxyRegistry,
    getAddresses().maker.proxyRegistry
  )
  let proxyAddress = await proxyRegistryInstance.methods
		.proxies(account)
		.call()
		.catch((e) => {
			throw Error(`proxyRegistryInstance proxies(): ${e.message}`)
		})
  return proxyAddress === "0x0000000000000000000000000000000000000000"
    ? null
    : proxyAddress
}

export const getUserCdps = async (proxyAddress, web3) => {
  let cdpManagerInstance = new web3.eth.Contract(CdpManager, getAddresses().maker.cdpManager)
  let count = await cdpManagerInstance.methods.count(proxyAddress).call()
  let cdps = {}
  
  if (count > 0) {
    let lastCdp = await cdpManagerInstance.methods.last(proxyAddress).call()
    cdps[lastCdp] = { loading: true }

    for (let i = 1; i < count; i++) {
      let linkedList = await cdpManagerInstance.methods.list(lastCdp).call()
      lastCdp = linkedList.prev
		  cdps[lastCdp] = { loading: true }
    }
  }
  return cdps
}

export const getCdpDetails = async (web3, cdpId) => {
	let networkId = await web3.eth.net.getId()
	let mkrActionsDeployed = MkrActions.networks[networkId]
	let mkrActions = new web3.eth.Contract(MkrActions.abi, mkrActionsDeployed && mkrActionsDeployed.address) 
	let addressProviderDeployed = AddressProvider.networks[networkId]
	
	let { debt, ilk, owner, collateral, spot, mat } = await mkrActions.methods.getCDPInfo(addressProviderDeployed.address, Number(cdpId)).call()
	let ilkCleaned = web3.utils.hexToAscii(ilk).split("\u0000").join("")
	if (debt > 0) {
		let cratio = ((((spot / 10**27) * (collateral / 10**18) * (mat / 10**27)) / (debt / 10**18)) * 100).toFixed(2)
		return { ilk: ilkCleaned, collateral: web3.utils.fromWei(collateral, "ether"), cratio, debt: web3.utils.fromWei(debt, "ether"), owner }
	} 
	return { ilk: ilkCleaned, collateral: web3.utils.fromWei(collateral, "ether"), cratio: 0, debt: 0, owner }
  }